import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, MenuItem, DialogActions } from '@mui/material';

const TaskForm = ({ open, onClose, onSave, task }) => {
  const [taskDetails, setTaskDetails] = useState({
    title: task ? task.title : '',
    description: task ? task.description : '',
    loe: task ? task.loe : 'Low',
    dueDate: task ? task.dueDate : '',
    status: task ? task.status : 'todo',
    assignee: task ? task.assignee : '',
    priority: task ? task.priority : 'Medium'
  });

  const handleChange = (e) => {
    setTaskDetails({ ...taskDetails, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    onSave(taskDetails);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{task ? 'Edit Task' : 'Create Task'}</DialogTitle>
      <DialogContent>
        <TextField
          label="Title"
          name="title"
          fullWidth
          margin="dense"
          value={taskDetails.title}
          onChange={handleChange}
        />
        <TextField
          label="Description"
          name="description"
          fullWidth
          margin="dense"
          multiline
          rows={4}
          value={taskDetails.description}
          onChange={handleChange}
        />
        <TextField
          label="Level of Effort (LoE)"
          name="loe"
          select
          fullWidth
          margin="dense"
          value={taskDetails.loe}
          onChange={handleChange}
        >
          {['Low', 'Medium', 'High'].map((level) => (
            <MenuItem key={level} value={level}>
              {level}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Due Date"
          name="dueDate"
          type="date"
          fullWidth
          margin="dense"
          InputLabelProps={{ shrink: true }}
          value={taskDetails.dueDate}
          onChange={handleChange}
        />
        <TextField
          label="Status"
          name="status"
          select
          fullWidth
          margin="dense"
          value={taskDetails.status}
          onChange={handleChange}
        >
          {['todo', 'in-progress', 'done'].map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Assignee"
          name="assignee"
          fullWidth
          margin="dense"
          value={taskDetails.assignee}
          onChange={handleChange}
        />
        <TextField
          label="Priority"
          name="priority"
          select
          fullWidth
          margin="dense"
          value={taskDetails.priority}
          onChange={handleChange}
        >
          {['Low', 'Medium', 'High'].map((priority) => (
            <MenuItem key={priority} value={priority}>
              {priority}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskForm;

import React from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';

const TaskCard = ({ task, onMoveTask, onEditTask }) => {
  return (
    <Card sx={{ mb: 2, bgcolor: '#555' }}>
      <CardContent>
        <Typography variant="h5">{task.title}</Typography>
        <Typography variant="body2">{task.description}</Typography>
        <Typography variant="body2">LoE: {task.loe}</Typography>
        <Typography variant="body2">Due: {task.dueDate}</Typography>
        <Typography variant="body2">Priority: {task.priority}</Typography>
        <Typography variant="body2">Assignee: {task.assignee}</Typography>
        <Button onClick={() => onMoveTask(task.id, 'todo')}>To Do</Button>
        <Button onClick={() => onMoveTask(task.id, 'in-progress')}>In Progress</Button>
        <Button onClick={() => onMoveTask(task.id, 'done')}>Done</Button>
        <Button onClick={() => onEditTask(task)}>Edit</Button>
      </CardContent>
    </Card>
  );
};

export default TaskCard;

import React, { useState } from 'react';
import { Container, Grid, Switch, Box, AppBar, Toolbar, Typography, Button } from '@mui/material';
import Column from './Column';
import TaskForm from './TaskForm';
import WalletConnect from './WalletConnect';
import LoginModal from './LoginModal';

const KanbanBoard = () => {
  const [tasks, setTasks] = useState([
    { id: 1, title: 'Task 1', description: 'Description 1', status: 'todo', loe: 'Low', dueDate: '', assignee: 'Alice', priority: 'Medium' },
    { id: 2, title: 'Task 2', description: 'Description 2', status: 'in-progress', loe: 'Medium', dueDate: '', assignee: 'Bob', priority: 'High' }
  ]);
  const [darkMode, setDarkMode] = useState(true);
  const [isTaskFormOpen, setIsTaskFormOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');

  const handleMoveTask = (taskId, newStatus) => {
    setTasks(tasks.map(task => task.id === taskId ? { ...task, status: newStatus } : task));
  };

  const handleCreateTask = () => {
    setCurrentTask(null);
    setIsTaskFormOpen(true);
  };

  const handleEditTask = (task) => {
    setCurrentTask(task);
    setIsTaskFormOpen(true);
  };

  const handleSaveTask = (taskDetails) => {
    if (currentTask) {
      setTasks(tasks.map(task => (task.id === currentTask.id ? { ...taskDetails, id: currentTask.id } : task)));
    } else {
      setTasks([...tasks, { ...taskDetails, id: tasks.length + 1 }]);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleWalletConnect = (address) => {
    setWalletAddress(address);
  };

  const handleWalletDisconnect = () => {
    setWalletAddress('');
  };

  const handleLogin = (userData, action) => {
    console.log(`User ${action} with data:`, userData);
  };

  return (
    <Box sx={{ bgcolor: darkMode ? '#333' : '#fff', color: darkMode ? '#fff' : '#000', minHeight: '100vh' }}>
      <AppBar position="static" color="transparent" sx={{ p: 2 }}>
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Coinban
          </Typography>
          <Switch checked={darkMode} onChange={toggleDarkMode} color="primary" />
          <WalletConnect onWalletConnect={handleWalletConnect} onWalletDisconnect={handleWalletDisconnect} />
          <Button variant="contained" onClick={() => setIsLoginModalOpen(true)} sx={{ ml: 2 }}>
            {walletAddress ? 'Login' : 'Login'}
          </Button>
        </Toolbar>
      </AppBar>
      <Container sx={{ pt: 3 }}>
        <Grid container spacing={2}>
          {['todo', 'in-progress', 'done'].map(status => (
            <Grid item xs={12} md={4} key={status}>
              <Column status={status} tasks={tasks.filter(task => task.status === (status))} onMoveTask={handleMoveTask} onEditTask={handleEditTask} />
            </Grid>
          ))}
        </Grid>
        <Button variant="contained" color="primary" onClick={handleCreateTask} sx={{ mt: 3 }}>
          Create Task
        </Button>
      </Container>
      <TaskForm
        open={isTaskFormOpen}
        onClose={() => setIsTaskFormOpen(false)}
        onSave={handleSaveTask}
        task={currentTask}
      />
      <LoginModal
        open={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onLogin={handleLogin}
        walletAddress={walletAddress}
      />
    </Box>
  );
};

export default KanbanBoard;


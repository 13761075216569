import React from 'react';
import { Paper, Typography } from '@mui/material';
import TaskCard from './TaskCard';

const Column = ({ status, tasks, onMoveTask, onEditTask }) => {
  return (
    <Paper elevation={3} sx={{ p: 2, bgcolor: '#444' }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {status === 'todo' && 'To Do'}
        {status === 'in-progress' && 'In Progress'}
        {status === 'done' && 'Done'}
      </Typography>
      {tasks.map((task) => (
        <TaskCard
          key={task.id}
          task={task}
          onMoveTask={onMoveTask}
          onEditTask={onEditTask}
        />
      ))}
    </Paper>
  );
};

export default Column;

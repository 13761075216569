import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import KanbanBoard from './components/KanbanBoard';
import WalletConnect from './components/WalletConnect';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <WalletConnect />
      <KanbanBoard />
    </ThemeProvider>
  );
}

export default App;

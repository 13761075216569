import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, DialogActions, Typography } from '@mui/material';

const LoginModal = ({ open, onClose, onLogin, walletAddress }) => {
  const [email, setEmail] = useState('');
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);

  const handleUseWallet = () => {
    if (walletAddress) {
      onLogin({ username: walletAddress }, 'useWallet');
    } else {
      alert('Wallet is not connected');
    }
    onClose();
  };

  const handleSignIn = () => {
    setShowSignIn(true);
  };

  const handleSignUp = () => {
    setShowSignUp(true);
  };

  const handleLoginWithEmail = (action) => {
    onLogin({ username: email }, action);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Login to Coinban</DialogTitle>
      <DialogContent>
        {!showSignIn && !showSignUp && (
          <>
            <Button variant="contained" color="primary" onClick={handleUseWallet}>
              Use Wallet
            </Button>
            <Button variant="contained" color="secondary" onClick={handleSignIn} sx={{ mt: 2 }}>
              Sign In
            </Button>
            <Button variant="contained" color="secondary" onClick={handleSignUp} sx={{ mt: 2 }}>
              Sign Up
            </Button>
          </>
        )}
        {showSignIn && (
          <>
            <TextField
              label="Email"
              fullWidth
              margin="dense"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={() => handleLoginWithEmail('signin')}>
              Sign In
            </Button>
          </>
        )}
        {showSignUp && (
          <>
            <TextField
              label="Email"
              fullWidth
              margin="dense"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button variant="contained" color="secondary" onClick={() => handleLoginWithEmail('signup')}>
              Sign Up
            </Button>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginModal;

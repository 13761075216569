import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { BrowserProvider } from 'ethers';

const WalletConnect = ({ onWalletConnect, onWalletDisconnect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [walletAddress, setWalletAddress] = useState('');

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const provider = new BrowserProvider(window.ethereum);
        const accounts = await provider.send('eth_requestAccounts', []);
        const address = accounts[0];
        setWalletAddress(address);
        onWalletConnect(address); // Notify parent component of wallet connection
      } catch (error) {
        console.error("Error connecting wallet:", error);
      }
    } else {
      alert('MetaMask is not installed');
    }
  };

  const disconnectWallet = () => {
    setWalletAddress('');
    onWalletDisconnect(); // Notify parent component of wallet disconnection
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!walletAddress ? (
        <Button variant="contained" color="primary" onClick={connectWallet}>
          Connect Wallet
        </Button>
      ) : (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleMenuOpen}
          >
            {`${walletAddress.substring(0, 6)}...${walletAddress.slice(-4)}`}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={disconnectWallet}>Disconnect</MenuItem>
          </Menu>
        </>
      )}
    </>
  );
};

export default WalletConnect;
